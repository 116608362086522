import {initCommonQuestions} from './components/common-questions.js';
import {initModals} from './components/modals.js';
import {initAudioSampleModals} from './components/modal-audio-samples.js';
import {initQuizzes} from './components/quiz.js';

document.addEventListener('DOMContentLoaded', () => {
	initCommonQuestions();
	initModals();
	initAudioSampleModals();
	initQuizzes();
});
