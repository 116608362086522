export function initCommonQuestions() {
	const questionItems = document.querySelectorAll('.questions-item');
	console.log('questionItems init');

	questionItems.forEach(item => {
		item.addEventListener('click', () => {
			console.log('question item clicked');
			item.classList.toggle('questions-item--active');
		});
	});
}
