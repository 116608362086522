import {waveSurfers} from './modal-audio-samples.js';

function openModal(modalId) {
	const modal = document.getElementById(modalId);
	console.log(modal);

	if (modal) {
		const modalBody = modal.querySelector('.modal__body');

		modal.classList.remove('hidden');

		if (modalBody) {
			/* DISABLED: modalBody.classList.add('animation-fade-in');
				modalBody.classList.remove('animation-fade-out'); */
		}

		document.body.classList.add('no-scroll');

		if (modal.classList.contains('quiz')) {
			setTimeout(() => {
				window.dispatchEvent(new Event('resize'));
			}, 100);
			setTimeout(() => {
				const quizLoading = modal.querySelector('.quiz-loading');
				if (quizLoading) {
					quizLoading.classList.add('hidden');
					quizLoading.classList.remove('.quiz-loading--dark');
				}
			}, 1500);
		}
	}
}

function closeModal(modalId) {
	const modal = document.getElementById(modalId);

	if (modal) {
		const modalBody = modal.querySelector('.modal__body');

		if (modalBody) {
			/* DISABLED: modalBody.classList.add('animation-fade-out');
				modalBody.classList.remove('animation-fade-in'); */
		}

		waveSurfers.forEach(wavesurfer => {
			wavesurfer.pause();
			wavesurfer.seekTo(0);
		});

		document.body.classList.remove('no-scroll');

		modal.classList.add('hidden');

		/* DISABLED: modalBody.addEventListener('animationend', () => {
				modal.classList.add('hidden');
			}, { once: true }); */
	}
}

function initModalOpeners() {
	const items = document.querySelectorAll('.modal-opener');
	items.forEach(item => {
		const targetModalId = item.getAttribute('data-target');
		item.addEventListener('click', () => openModal(targetModalId));
	});
}

function initCloseModals() {
	const closeButtons = document.querySelectorAll('.modal-close');
	closeButtons.forEach(button => {
		const targetModalId = button.getAttribute('data-target');
		button.addEventListener('click', () => closeModal(targetModalId));
	});
}

export function initModals() {
	initModalOpeners();
	initCloseModals();
}
